<section id="main" [ngClass]="{'not-logged': !auth}">
    <ng-template [ngIf]="auth">
        <header>
            <app-navbar></app-navbar>
        </header>
    </ng-template>
    <nav *ngIf="!auth">
        <img src="../assets/img/logo/saluda+.png" class="realinver-realidades-de-inversion">
    </nav>
    <router-outlet></router-outlet>
</section>
