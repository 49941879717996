import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotLoggedGuard } from './shared/guards/not_logged.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: '', 
    redirectTo:'main',
    pathMatch:'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate:[NotLoggedGuard]
  },
  
  { 
    path: 'main', 
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule),
    canActivate:[AuthGuard]
  },
  { 
    path: 'courses', 
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesModule),
    canActivate:[AuthGuard]
  },
  { 
    path: 'courses/:id/sessions', 
    loadChildren: () => import('./pages/sessions/sessions.module').then(m => m.SessionsModule),
    canActivate:[AuthGuard]
  },
  { 
    path: 'distributors', 
    loadChildren: () => import('./pages/sellers/sellers.module').then(m => m.SellersModule),
    canActivate:[AuthGuard]
  },
  { 
    path: 'users', 
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
    canActivate:[AuthGuard]
  },
  { 
    path: 'policies', 
    loadChildren: () => import('./pages/policies/policies.module').then(m => m.PoliciesModule),
    canActivate:[AuthGuard]
  },
  { 
    path: 'brands', 
    loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule),
    canActivate:[AuthGuard]
  },
  
 
  
  {path: '**', component:NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
